<template>
  <b-modal :header="false" :header-text="'Advanced Filter'" :is-self-serve-modal="true">
    <div slot="modal-body" class="modal-wrapper-box" @mousedown="onClickOutside">
      <div class="modal-container">
        <div class="icon-header">
          <font-awesome-icon :icon="['far', 'times']" class="close-filters" @click="onClickCross" />
        </div>
        <div class="advance-filter-container">
          <div class="advance-filter-row">
            <b-select-checkbox
              ref="ioOptionDropdown"
              :options="filters.ioOptions || ioOptions"
              title=""
              header="Campaign"
              class="media-select"
              component-id="ioOptionDropdown"
              :disabled="isAdGroupLoading"
              :show-all="filters.ioOptions.length > 1 || ioOptions.length > 1"
              @selected-options="onSelectIO"
            />
            <b-select-checkbox
              ref="adGroupDropDown"
              :options="filters.adGroupOptions || adGroupOptions"
              title=""
              header="Ad Group"
              class="ad-group-select"
              component-id="adGroupDropDown"
              :disabled="isMniAccount"
              :show-all="filters.adGroupOptions.length > 1 || adGroupOptions.length > 1"
              @selected-options="onSelectAdGroups"
            />
          </div>
          <div class="advance-filter-row-option">
            <div class="advance-filter-row-option-label">Filter by Media Type</div>
            <b-hover-options-dropdown
              :label="selectedFilters.mediaTypeOptions.length ? '' : 'Select Media Type'"
              :options="filters.mediaTypeOptions"
              :fancy="true"
              :multiple="true"
              label-key="value"
              :value="selectedFilters.mediaTypeOptions ? selectedFilters.mediaTypeOptions : []"
              :class-name="'advance-filter-hover-mediatype'"
              @input="(val) => setSelectedFilters('mediaTypeOptions', val)"
            />
          </div>
          <div class="advance-filter-row-option">
            <div class="advance-filter-row-option-label">
              Filter by Creatives
            </div>
            <b-hover-options-dropdown
              key="creatives"
              :label="selectedFilters.creativeOptions.length ? '' : 'Select Creatives'"
              :options="filters.creativeOptions || creativeOptions"
              :fancy="true"
              :multiple="true"
              label-key="value"
              :value="selectedFilters.creativeOptions ? selectedFilters.creativeOptions : []"
              :class-name="'advance-filter-hover-creatives'"
              @input="(val) => setSelectedFilters('creativeOptions', val)"
            />
          </div>
          <div class="advance-filter-row-option">
            <div class="advance-filter-row-option-label">Filter by Publishers</div>
            <b-hover-options-dropdown
              :label="selectedFilters.publisherOptions.length ? '' : 'Select Publishers'"
              :options="filters.publisherOptions || publisherOptions"
              :fancy="true"
              :multiple="true"
              label-key="value"
              :value="selectedFilters.publisherOptions"
              :class-name="'advance-filter-hover-publishers'"
              @input="(val) => setSelectedFilters('publisherOptions', val)"
            />
          </div>

          <div class="advance-filter-row-option">
            <div class="advance-filter-row-option-label">
              {{ isMniAccount ? 'Filter by Placements' : 'Filter by Audiences' }}
            </div>
            <b-hover-options-dropdown
              :label="
                selectedFilters.audienceOptions.length
                  ? ''
                  : isMniAccount
                  ? 'Select Placements'
                  : 'Select Audiences'
              "
              :options="filters.audienceOptions || audienceOptions"
              :fancy="true"
              :multiple="true"
              label-key="value"
              :value="selectedFilters.audienceOptions ? selectedFilters.audienceOptions : []"
              :class-name="'advance-filter-hover-audiences'"
              @input="(val) => setSelectedFilters('audienceOptions', val)"
            />
          </div>
        </div>
      </div>
    </div>
    <div slot="modal-footer" class="action-ftr">
      <div class="actions-btn">
        <k-button :size="3" label="SAVE" class="mr-20" @click="onClickCancel" />
        <k-button :size="3" label="CLEAR ALL" class="mr-20 scn-btn" @click="onClickClearAll" />
      </div>
    </div>
  </b-modal>
</template>

<script>
import { get } from 'vuex-pathify';
import advertiserReportsApi from '~/api/advertiser-reports';
import BHoverOptionsDropdown from '~/components/elements/b-hover-options-dropdown.vue';
import BSelectCheckbox from '~/components/elements/b-select-checkbox.vue';
import { buildQueryString } from '~/helpers/global/url-helpers';
import { formatDateForAPI } from '~/util/apiDateFormat';

export default {
  name: 'AdvanceFiltersModal',
  components: {
    BModal: () => import(/* webpackChunkName: "b-modal" */ '~/components/elements/b-modal.vue'),
    BSelectCheckbox,
    BHoverOptionsDropdown,
  },
  props: {
    hideConfirmBtn: {
      type: Boolean,
      required: false,
      default: false,
    },
    filters: {
      type: Object,
      required: false,
      default: () => {},
    },
    isAdGroupLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    onSelectIO: {
      type: Function,
      required: false,
      default: () => {},
    },
    onSelectAdGroups: {
      type: Function,
      required: false,
      default: () => {},
    },
    onSelectMediaTypes: {
      type: Function,
      required: false,
      default: () => {},
    },
    setFilterOptions: {
      type: Function,
      required: false,
      default: () => {},
    },
  },
  data() {
    const filterOptionsArray = [
      'publisherOptions',
      'audienceOptions',
      'creativeOptions',
      'adGroupOptions',
      'mediaTypeOptions',
      'ioOptions',
    ];
    return {
      selectedFilters: this.getSelectedFilters(this.filters, filterOptionsArray),
      initialFilters: JSON.parse(JSON.stringify(this.filters)),
      audienceOptions: [],
      creativeOptions: [],
      publisherOptions: [],
      filterOptionsArray,
    };
  },
  computed: {
    advertiser: get('common/advertiser'),
    account: get('common/account'),
    adGroupOptions: get('dashboard/filters@adGroupOptions'),
    ioOptions: get('dashboard/filters@ioOptions'),
    dates: get('dashboard/dates'),
    isMniAccount: get('common/isMniAccount'),
  },
  async mounted() {
    if (this.ioOptions.length) {
      this.onSelectIO(this.ioOptions);
    }
    this.getAudiencesOptions();
    this.getPublisherOptions();
    this.getCreativesOptions();
    window.addEventListener('click', this.onClickOutside);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.onClickOutside);
  },
  methods: {
    onClickCancel(ev) {
      this.$emit('cancel', ev);
    },
    getSelectedFilters(filters, filterOptionsArray) {
      const selectFilterVals = {};
      filterOptionsArray?.forEach((fl) => {
        if (filters[fl]?.length) {
          selectFilterVals[fl] = filters[fl]?.filter((f) => f.checked);
        } else {
          selectFilterVals[fl] = [];
        }
      });
      return selectFilterVals;
    },
    async getAudiencesOptions() {
      if (this?.filters?.audienceOptions?.length > 0) {
        this.audienceOptions = this.filters.audienceOptions;
        return;
      }
      const data = await advertiserReportsApi.audiences(
        this.advertiser ? this.advertiser.id : 0,
        buildQueryString({
          advertiser: this.advertiser ? this.advertiser.name : '',
          client: this.account ? this.account.name : '',
          endDate: formatDateForAPI(this.dates.endDate),
          startDate: formatDateForAPI(this.dates.startDate),
        })
      );
      const filterOptions = [];
      const seenKeys = new Set(); // Set to track unique keys

      if (data.length > 0) {
        // data?.forEach((d) => filterOptions.push({ key: d.value, value: d.key, checked: false }));
        data?.forEach((d) => {
          // Check if the key has already been added
          if (!seenKeys.has(d.key)) {
            filterOptions.push({ key: d.value, value: d.key, checked: false });
            seenKeys.add(d.key); // Add the key to the set of seen keys
          }
        });
      }
      this.audienceOptions = filterOptions;
      this.setFilterOptions('audienceOptions', filterOptions);
    },
    async getCreativesOptions() {
      if (this?.filters?.creativeOptions?.length > 0) {
        this.creativeOptions = this.filters.creativeOptions;
        return;
      }
      const data = await advertiserReportsApi.creatives(
        this.advertiser ? this.advertiser.id : 0,
        buildQueryString({
          advertiser: this.advertiser ? this.advertiser.name : '',
          client: this.account ? this.account.name : '',
          endDate: formatDateForAPI(this.dates.endDate),
          startDate: formatDateForAPI(this.dates.startDate),
        })
      );
      const filterOptions = [];
      if (data.length) {
        data?.forEach((d) => filterOptions.push({ ...d, checked: false }));
      }
      this.creativeOptions = filterOptions;
      this.setFilterOptions('creativeOptions', filterOptions);
    },
    async getPublisherOptions() {
      if (this?.filters?.publisherOptions?.length > 0) {
        this.publisherOptions = this.filters.publisherOptions;
        return;
      }
      const data = await advertiserReportsApi.publishers(
        this.advertiser ? this.advertiser.id : 0,
        buildQueryString({
          advertiser: this.advertiser ? this.advertiser.name : '',
          client: this.account ? this.account.name : '',
          endDate: formatDateForAPI(this.dates.endDate),
          startDate: formatDateForAPI(this.dates.startDate),
        })
      );
      const filterOptions = [];
      if (data.length) {
        data?.forEach((d) => filterOptions.push({ ...d, checked: false }));
      }
      this.publisherOptions = filterOptions;
      this.setFilterOptions('publisherOptions', filterOptions);
    },

    onClickOutside(e) {
      const refArray = ['adGroupDropDown', 'ioOptionDropdown'];
      if (e?.composedPath()?.filter((p) => p?.id === 'adGroupDropDown')?.length > 0) {
        this?.$refs.ioOptionDropdown?.closeDropdown();
        return;
      }
      if (e?.composedPath()?.filter((p) => p?.id === 'ioOptionDropdown')?.length > 0) {
        this?.$refs.adGroupDropDown?.closeDropdown();
        return;
      }
      refArray.forEach((ref) => {
        if (
          !(e.target.parentNode?.className === 'norm-option') &&
          !(e.target.parentNode?.className === 'dropdown-menu') &&
          !(e.target?.nodeName === 'path')
        ) {
          this?.$refs[ref]?.closeDropdown();
        }
      });
    },
    setSelectedFilters(name, values) {
      const formattedOption = this.filters[name].map((opt) => {
        if (values.find((val) => val.value === opt.value)) {
          opt.checked = true;
        } else {
          opt.checked = false;
        }
        return opt;
      });
      this.setFilterOptions(name, formattedOption);
      this.selectedFilters[name] = values.map((val) => {
        val.checked = true;
        return val;
      });
    },
    onClickClearAll(ev) {
      this.filterOptionsArray.forEach((filter) => {
        if (
          this.initialFilters[filter] &&
          (filter === 'adGroupOptions' || filter === 'ioOptions')
        ) {
          this.setFilterOptions(filter, this.initialFilters[filter]);
        } else if (filter === 'mediaTypeOptions') {
          this.setFilterOptions(
            filter,
            this.filters.mediaTypeOptions.map((ff) => {
              ff.checked = false;
              return ff;
            })
          );
        } else {
          this.setFilterOptions(filter, []);
        }
      });
      this.$emit('cancel', ev);
    },
    onClickCross(ev) {
      this.filterOptionsArray.forEach((filter) => {
        if (this.initialFilters[filter]) {
          this.setFilterOptions(filter, this.initialFilters[filter]);
        } else {
          this.setFilterOptions(filter, []);
        }
      });
      this.$emit('cancel', ev);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-body .form-input {
  margin: unset !important;
}
::v-deep .modal-container {
  width: 780px !important;
  padding: 20px 0px 0px 0px !important;
  overflow-x: hidden;
}
::v-deep .advance-filter-container .modal-mask .modal-wrapper {
  overflow: auto !important;
}
::v-deep .modal-container .selfServeContainer {
  width: 780px !important;
  padding: 13px 30px 15px;
  font-size: 19px;
  letter-spacing: 0.02em;
  text-align: left;
  background-color: #25292e;
  color: #fff;
  height: unset;
  border-bottom: unset;
}
::v-deep .modal-header.row.end-xs {
  display: none;
}
::v-deep .selfServBox .modal-container .modal-footer {
  width: 780px;
  height: unset;
  padding: 0px 30px 30px 30px;
  text-align: left;
  background-color: #212429;
}
::v-deep .light-theme {
  .selfServBox {
    .modal-container .modal-footer {
      .scn-btn .k-btn {
        background-color: transparent;
        &:hover {
          background-color: #424242 !important;
          color: #cad1d6 !important;
        }
      }
    }
  }
}
::v-deep .selfServBox .modal-container {
  min-width: unset !important;
  max-width: unset !important;
  overflow: unset;
  padding: unset !important;
  width: unset !important;
}
::v-deep .selfServBox .modal-container .header-close {
  top: 15px;
}

::v-deep .selfServBox .modal-container .header-txt {
  color: #fff;
}
::v-deep .selfServBox .modal-wrapper-box {
  padding: 30px;
  background-color: #212429;
  min-height: unset !important;
  width: 780px;
}

.light-theme {
  .selfServBox {
    .modal-wrapper-box {
      color: #212325 !important;
      box-shadow: 0 14px 35px -12px rgba(0, 40, 100, 0.17) !important;
      background-color: #fff !important;
      // border: 1px solid rgba(165, 173, 178, 0.22) !important;
      border-radius: 8px 8px 0 0 !important;
      transition: background-color 0.3s ease !important;
    }
  }
  .advance-filter-container {
    .advance-filter-row {
      display: flex;
      justify-content: space-between;
      .dropdown .select {
        background-color: #fff;
        border: 1px solid #c5d2d8;
        color: #555;
      }
    }
    .advance-filter-row-option {
      .advance-filter-row-option-label {
        color: #555;
      }
      .form-input-wrap {
        background-color: #fff;
        // border: 1px solid #c5d2d8;
        color: #555;
        .form-input .form-input-field.v-select .vs__dropdown-toggle {
          background-color: #fff;
          color: #555;
        }
      }
    }
    ::v-deep .form-input .form-input-field {
      background-color: transparent !important;
    }
    ::v-deep .form-input .form-input-field.v-select {
      border: 1px solid #c5d2d8 !important;
      .vs__search {
        background-color: #fff !important;
        color: #555 !important;
      }
      .vs__dropdown-toggle {
        background-color: #fff !important;
        color: #555 !important;
      }
    }
  }
}

.advance-filter-container {
  .advance-filter-row {
    display: flex;
    justify-content: space-between;
    .dropdown {
      width: 344px;
    }
  }
  .advance-filter-row-option {
    margin-top: 30px;
    .advance-filter-row-option-label {
      text-align: left;
      color: #fff;
      font-size: 14px;
      margin: 0px 0px 10px 0px;
    }
  }
}

.mr-20 {
  margin-right: 20px;
}
::v-deep .form-input .form-input-field.v-select .vs__dropdown-toggle {
  background-color: #30323a;
}
::v-deep .modal-body .form-input .form-input-field.v-select {
  border: 1px solid rgba(170, 170, 170, 0.2);
  border-radius: 4px;
}
::v-deep .form-input .form-input-field.v-select .vs__search {
  font-family: 'Source Sans Pro', sans-serif;
  color: #8d9aa6;
  background-color: #30323a;
  font-size: 14px;
}
::v-deep .form-input-wrap {
  cursor: pointer;
  background-color: #30323a;
  border-radius: unset;
  position: relative;
}

::v-deep .form-input .form-input-field.v-select.multiple-input-field .vs__selected-options {
  padding: 10px;
}

::v-deep .form-input .form-input-field.v-select.vs--open .vs__dropdown-toggle {
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
}
::v-deep .form-input .form-input-field.v-select .vs__dropdown-toggle .vs__selected {
  background: var(--primarycolor) !important;
  color: #fff !important;
  font-weight: 400;
  font-size: 13px;
  //font-family: 'Manrope', sans-serif;
  font-family: 'Source Sans Pro', sans-serif;
  padding: 2px 7px;
  margin: 2px;
  border-radius: 2px;
  display: inline-block;
  border: unset;
  opacity: 1;
  max-width: 680px;
}
::v-deep .form-input .form-input-field.v-select .vs__dropdown-toggle .vs__selected .vs__deselect {
  fill: rgba(255, 255, 255, 0.8);
}
.icon-header {
  position: relative;
  display: block;
  height: 40px;
  .close-filters {
    position: absolute;
    right: 0;
    cursor: pointer;
    color: #b8c2cc;
    font-size: 18px;
    &:hover {
      color: var(--primarycolor) !important;
    }
  }
}
</style>
<style land="scss">
body.light-theme.advance-filters .selfServBox .modal-container .modal-footer {
  background-color: #fff !important;
  border-radius: 0 0 8px 8px !important;
  text-align: center;
}
body.light-theme.advance-filters .vs__dropdown-menu {
  max-width: 718px;
  background-color: #f9f9f9 !important;
  border: 1px solid rgba(170, 170, 170, 0.2) !important;
  /* color: #555 !important;
  font-size: 13px !important; */
  padding: 0 !important;
  .vs__dropdown-option {
    color: #555 !important;
    font-size: 13px !important;
    text-wrap: pretty;
  }
  .vs__dropdown-option.vs__dropdown-option--highlight {
    background-color: rgba(0, 0, 0, 0.04) !important;
  }
}
/* body.advance-filters .vs__dropdown-menu .vs__dropdown-option.vs__dropdown-option--highlight {
    color: #8d9aa6;
    background: rgba(0, 0, 0, 0.1);
    font-family: 'Source Sans Pro', sans-serif;
} */
body.advance-filters .vs__dropdown-menu {
  background: #2d2f34;
  color: #8d9aa6;
  border: 1px solid rgba(170, 170, 170, 0.2);
  font-family: 'Source Sans Pro', sans-serif;
}
body.advance-filters .vs__dropdown-menu .vs__dropdown-option {
  color: #8d9aa6;
  font-family: 'Source Sans Pro', sans-serif;
}
body.advance-filters .vs__dropdown-menu .vs__dropdown-option:hover {
  color: #fff;
  background: rgba(0, 0, 0, 0.1);
  font-family: 'Source Sans Pro', sans-serif;
}
body.advance-filters .vs__dropdown-hover:active {
  color: #8d9aa6;
  background: rgba(0, 0, 0, 0.1);
  font-family: 'Source Sans Pro', sans-serif;
}
body.advance-filters .vs__dropdown-hover span {
  color: unset !important;
  font-size: 14px !important;
  padding: 3px 20px !important;
  font-family: 'Source Sans Pro', sans-serif;
}
body.advance-filters .vs__dropdown-hover span:hover {
  color: #fff;
  font-family: 'Source Sans Pro', sans-serif;
}
body.advance-filters .vs__dropdown-hover span:active {
  color: #8d9aa6;
  background: rgba(0, 0, 0, 0.1);
  font-family: 'Source Sans Pro', sans-serif;
}
body.advance-filters .vs__dropdown-menu .vs__dropdown-option.vs__dropdown-option--highlight {
  color: #8d9aa6;
  background: rgba(0, 0, 0, 0.1);
  font-family: 'Source Sans Pro', sans-serif;
}
body .vs__dropdown-menu .vs__dropdown-option.vs__dropdown-option--highlight:active {
  color: #8d9aa6;
  background: rgba(0, 0, 0, 0.1);
  font-family: 'Source Sans Pro', sans-serif;
}
body.advance-filters .vs__dropdown-menu .vs__dropdown-option:hover {
  background: rgba(0, 0, 0, 0.1);
  font-family: 'Source Sans Pro', sans-serif;
}
.disabled {
  pointer-events: none;
  opacity: 0.5;
}
</style>
